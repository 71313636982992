import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }

  login(email, password){
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  getUserDomain(uid){
    return firebase.firestore().collection('UserDetails').doc(uid).get();
  }

  getUserRole(uid){
    return firebase.firestore().collection('UserRoles').doc(uid).get();
  }

  forgotPassword(){
    console.log("here");
    debugger;
    var req = new XMLHttpRequest();
    var url = "https://us-central1-user-verification-a3665.cloudfunctions.net/sendForgotPasswordEmail";    
    req.open('POST', url, true);
    req.setRequestHeader('Content-Type', 'application/json');
    req.send();
  }  
}
