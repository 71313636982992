import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  

  getUsers() {
    const httpHeaders = new HttpHeaders ({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + 'Basic ZDJiODA5OWEtZTFiOC01ODE1LTAyZGQtZTk1Mjc3YWJmZjY2OjAwYjJlY2NjLWFkMTUtZWQ1My0zZjNkLTc1MGUyOGZkYzVmMw==',
      'thinkdigital-token': 'TESTING'
    });
    return this.http.get('https://prod.thinkdigital.co.za/services/gowaterfall/Users', { headers: httpHeaders });
  }

  getCojUsers() {
    const httpHeaders = new HttpHeaders ({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + 'Basic ZDJiODA5OWEtZTFiOC01ODE1LTAyZGQtZTk1Mjc3YWJmZjY2OjAwYjJlY2NjLWFkMTUtZWQ1My0zZjNkLTc1MGUyOGZkYzVmMw==',
      'thinkdigital-token': 'TESTING'
    });
    return this.http.get('https://prod.thinkdigital.co.za/services/gowaterfall/COJUserAccount', { headers: httpHeaders });
  }

  getEstates() {
    const httpHeaders = new HttpHeaders ({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + 'Basic ZDJiODA5OWEtZTFiOC01ODE1LTAyZGQtZTk1Mjc3YWJmZjY2OjAwYjJlY2NjLWFkMTUtZWQ1My0zZjNkLTc1MGUyOGZkYzVmMw==',
      'thinkdigital-token': 'TESTING'
    });
    return this.http.get("https://prod.thinkdigital.co.za/services/gowaterfall/ServiceProviders?$filter=serviceType%20eq%20'Estate'", { headers: httpHeaders });
  }
}
