import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule }  from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { HighlightDirective } from './highlight.directive';
import { MatButtonModule } from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { EstateDialogComponent } from './estate-dialog/estate-dialog.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FilterPipe } from './filter.pipe';
import { FormsModule }   from '@angular/forms';
import { MatSidenavModule } from '@angular/material';
import { LoginComponent } from './login/login.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';



@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    AboutComponent,
    ContactComponent,
    HomeComponent,
    HighlightDirective,
    EstateDialogComponent,
    FilterPipe,
    LoginComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    ToastrModule.forRoot(),
    MatSelectModule,
    MatDialogModule ,
    Ng2SearchPipeModule,
    FormsModule,
    MatSidenavModule,
    NgxSmartModalModule.forRoot()
  ],
  exports: [
    FilterPipe
  ],  
  providers: [FilterPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
