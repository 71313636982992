import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { DataService } from '../data.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FilterPipe } from '../filter.pipe';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  users: any;
  estates: any;
  filteredUsers: any;
  tempUsers:any;
  tUsers:any;
  

  constructor(private data: DataService,public http: HttpClient,private toastr: ToastrService, public pipe: FilterPipe, public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.getFilteredUsers();
    
    this.data.getEstates().subscribe(data => {
      this.estates = data;
      console.log(this.estates)
    })
  }

  verifyUser (userId, i) {
    const httpHeaders = new HttpHeaders ({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + 'Basic ZDJiODA5OWEtZTFiOC01ODE1LTAyZGQtZTk1Mjc3YWJmZjY2OjAwYjJlY2NjLWFkMTUtZWQ1My0zZjNkLTc1MGUyOGZkYzVmMw==',
      'thinkdigital-token': 'TESTING'
    });
    this.http.put('https://prod.thinkdigital.co.za/services/gowaterfall/Users('+ userId +')',
    {
      "status": "Verified"
    },
    { 
      headers: httpHeaders 
    }
    ).subscribe(
        data => {
        this.users.value[i].status = "Verified";
        console.log("PUT Request is successful ", data);
        this.showSuccess();
        
      },
      error => {
        console.log("Error", error);
        this.showError();
      }
    );
  }

  showSuccess() {
    this.toastr.success('User successfully verified!');
  }


  showError() {
    this.toastr.error('User verification failed!');
  }

  editUserEstate(){

  }

  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }
  fakeValidateUserData(userId,index) {
    return of(this.users.value[index]);
  }

  fakeValidateAllUserData() {
    if(this.filteredUsers){
      return of(this.filteredUsers);
    } else {
      return of(this.users.value);
    }    
  }

  fakeValidateUserDataView(userId,index) {
    if(this.filteredUsers && this.filteredUsers.length >0){
      return of(this.filteredUsers[index]);
    } else {
      return of(this.users.value[index]);
    }    
  }

  dynamicDownloadTxt(userID, i) {
    let userId = userID;
    let index = i;

    this.fakeValidateUserData(userId,index).subscribe((res) => {
      this.dyanmicDownloadByHtmlTag({
        fileName: 'User Json',
        text: JSON.stringify(res)
      });
    });
  }

  dynamicDownloadJson(userId,index) {
    this.fakeValidateUserData(userId,index).subscribe((res) => {
      this.dyanmicDownloadByHtmlTag({
        fileName: 'My Report.json',
        text: JSON.stringify(res)
      });
    });
  }

  getFilteredUsers() {
    this.data.getUsers().subscribe(data => {
      this.tempUsers = data;
      var count = 0;
      this.tUsers = [];
      for (let i = 0; i < this.tempUsers.value.length; i++) {
        const user = this.tempUsers.value[i];
        if(user.accounts){
          if(user.accounts.length > 0 && (!user.status || user.status === 'Pending')) {
            this.tUsers[count] = user;
            count++
          }
        }        
      }
      console.log(this.tUsers);
      this.users = {};
      this.users.value= this.tUsers;
    })
  }

  private dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }

  dynamicDownloadTxtFiltered() {  
    this.fakeValidateAllUserData().subscribe((res) => {
      this.dyanmicDownloadByHtmlTag({
        fileName: 'User Json',
        text: JSON.stringify(res)
      });
    });
  }

  searchUsers(searchText) {
  let users = this.users.value;
   this.pipe.transform(users,searchText);
   let a = localStorage.getItem('filteredUsers');
   let filteredUserObj = JSON.parse(a);
   console.log(filteredUserObj);
   this.filteredUsers = filteredUserObj;
  }

  setViewUserJson(userId,index) {
    const obj = this.fakeValidateUserDataView(userId,index);
    this.ngxSmartModalService.setModalData(obj, 'myModal');
  }

  clearData() {
    this.ngxSmartModalService.resetModalData('myModal');
  }

}
