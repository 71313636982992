import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as firebase from 'firebase';

if (environment.production) {
  enableProdMode();
}

const firebaseConfig = {
  apiKey: "AIzaSyD9sSqs1lvw1YNLiHuf55NzNQuT5FrpwbY",
  authDomain: "user-verification-a3665.firebaseapp.com",
  databaseURL: "https://user-verification-a3665.firebaseio.com",
  projectId: "user-verification-a3665",
  storageBucket: "user-verification-a3665.appspot.com",
  messagingSenderId: "214187922857"
};

firebase.initializeApp(firebaseConfig);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
