import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  loginDetailsIncorrect: boolean;

  constructor(public service:LoginService, public router:Router) { }

  ngOnInit() {
  }
  
  login(){
    this.service.login(this.username,this.password).then((info) => {
      console.log('user logged in');
      console.log(info.user.uid);
      this.service.getUserDomain(info.user.uid).then(doc => {
        let userDetails = doc.data();        
        this.service.getUserRole(info.user.uid).then(docRole => {
          let role= docRole.data();
          this.loginDetailsIncorrect = false;
          localStorage.setItem('currentUser', JSON.stringify({ username: this.username , uid: info.user.uid, userDetails, role}));
          let currentUser = localStorage.getItem('currentUser');
          this.router.navigateByUrl('/');
        }).catch(err => {
          console.log(err)
        })
      }).catch(err =>{
        console.log(err);
      });
      
    }).catch((err) => {
      console.log(err);
      this.loginDetailsIncorrect = true;
    });
  }

  userForgotPassword(){
    this.service.forgotPassword();
  }

  // forgotPassword() {
  //   // var actionCodeSettings = {
  //   //   url: 'https://userverification.thinkdigital.co.za/',
  //   //   // iOS: {
  //   //   //   bundleId: 'com.example.ios'
  //   //   // },
  //   //   // android: {
  //   //   //   packageName: 'com.example.android',
  //   //   //   installApp: true,
  //   //   //   minimumVersion: '12'
  //   //   // },
  //   //   //  handleCodeInApp: true
  //   // };
  //   firebase.auth().sendPasswordResetEmail(
  //       'jarryd.dutoit@thinkdigital.co.za')
  //       .then(function() {
  //         // Password reset email sent.
  //       })
  //       .catch(function(error) {
  //         // Error occurred. Inspect error.code.
  //       });
  //     }
}