import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    // if(!items) localStorage.setItem('filteredUsers', '');
    // if(!searchText) localStorage.setItem('filteredUsers', '');
    if(!items) return [];    
    if(!searchText) return items;
      searchText = searchText.toLowerCase();
      let filteredUsers =[];
      return items.filter( it => {
          let userId = it._id;          
          if(userId.toString().toLowerCase().includes(searchText)){
              filteredUsers.push(it);
              localStorage.setItem('filteredUsers',JSON.stringify(filteredUsers));
              return filteredUsers               
          }
      });
    } 
}